.chakra-select__wrapper {
  margin-inline-start: 0px !important;
}
.radio-toolbar {
    cursor: pointer;

  }
  
  .radio-toolbar input[type="radio"] {
    opacity: 0;
    position: fixed;
    width: 0;
  }
  
  .radio-toolbar label {
      display: inline-block;
      background-color: #fff;
      padding: 10px 10px;
      font-family: sans-serif, Arial;
      font-size: 16px;
      border: 1px solid #E2E8F0;
      border-radius: 4px;
    cursor: pointer;

  }
  
  .radio-toolbar label:hover {
    background-color: rgba(42,42,42, 0.1);
  }
  
  .radio-toolbar input[type="radio"]:focus + label {
      border: 2px dashed #444;
  }
  
  .radio-toolbar input[type="radio"]:checked + label {
      background-color: #292929;
      border-color: #292929;
      color: #fff;
  }
  
@media only screen and (max-width: 600px) {

.radio-toolbar {
    cursor: pointer;

  }
  
  .radio-toolbar input[type="radio"] {
    opacity: 0;
    position: fixed;
    width: 0;
  }
  
  .radio-toolbar label {
      display: inline-block;
      background-color: #fff;
      padding: 5px 5px;
      font-family: sans-serif, Arial;
      font-size: 12px;
      border: 1px solid #E2E8F0;
      border-radius: 4px;
    cursor: pointer;

  }
  
  .radio-toolbar label:hover {
    background-color: rgba(42,42,42, 0.1);
  }
  
  .radio-toolbar input[type="radio"]:focus + label {
      border: 2px dashed #444;
  }
  
  .radio-toolbar input[type="radio"]:checked + label {
      background-color: #292929;
      border-color: #292929;
      color: #fff;
}
}